/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { MdDeleteForever } from "react-icons/md";
import {
  IoIosCloseCircleOutline,
  IoIosCheckmarkCircleOutline,
} from "react-icons/io";

import Connect from "../../store/config/connect";

import {
  getAllPetsByCustomer,
  getBravectoProducts,
  postStoreById,
  postRegisterCoupon,
} from "../../services/services";

import SelectCustom from "../Utils/Select/Select";
import Input from "../Utils/Input/Input";
import LoaderForButton from "../Utils/LoaderForButtons/LoaderForButtons";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Loader from "../Loader/Loader";
import Modal from "../Modal/Modal";
import CadastrarPet from "../CadastrarPet/CadastrarPet";
import ConfirmModal from "./ConfirmModal";

import "./CadastrarNotaFiscal.css";

import iconPdf from '../../images/iconPdf.svg'

const CadastrarNotaFiscal = (props) => {
  document.title = "Clube Bravecto | Cadastrar Nota";

  const [isCreatingNewPet, setIsCreatingNewPet] = useState(false);
  const [errorPhoto, setErrorPhoto] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const [checkImageBase64, setCheckImageBase64] = useState(true);
  const [hasFormErrors, setHasFormErrors] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [hasRequestErrors, setHasRequestErrors] = useState(false);
  const [modalUploadImageClose, setModalUploadImageClose] = useState(false);
  const [modalConfirmSubmit, setModalConfirmSubmit] = useState(false);
  const [modalRegisterReminderClose, setModalRegisterReminderClose] =
    useState(false);
  const [isFilePDF, setIsFilePDF] = useState(false);

  const [purchaseDateValue, setPurchaseDateValue] = useState(null);
  const [inputPurchaseDate] = useState({
    type: "text",
    name: "purchaseDate",
    maxLength: 10,
    replaceValueOnChange: true,
    placeholder: "DD/MM/AAAA",
    onChange: function (birthday) {
      birthday =
        birthday.length > 0 && birthday.slice(0, 2) > 31 ? 31 : birthday;
      birthday =
        birthday.length > 3 && birthday.slice(3, 5) > 12
          ? birthday.slice(0, 3) + 12
          : birthday;
      let v = birthday
        .toString()
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{2})(\d)/, "$1/$2");
      return v;
    },
    onBlur: function (e) {
      dataLayer.push({
        event: "cadastro_nota_preencheu_data"
      });
      setPurchaseDateValue(e.target.value);
    },
    onFocus: function () {
      pushDataLayer("focus", "purchaseDate");
    },
  });

  const [cnpjValue, setCnpjValue] = useState(null);
  const [inputCnpj] = useState({
    type: "text",
    name: "cnpj",
    maxLength: 18,
    replaceValueOnChange: true,
    placeholder: "00.000.000/0000-00",
    onChange: function (cnpj) {
      // eslint-disable-next-line
      let v = cnpj;
      v = v.replace(/\D/g, "");
      v = v.replace(/^(\d{2})(\d)/, "$1.$2");
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
      return v;
    },
    onBlur: function (e) {
      dataLayer.push({
        event: "cadastro_nota_preencheu_cnpj"
      });
      setCnpjValue(e.target.value);
    },
    onFocus: function () {
      pushDataLayer("focus", "cnpj");
    },
  });

  useEffect(() => {
    if (cnpjValue) {
      let body = {
        cnpj: cnpjValue,
      };
      postStoreById(body, props.user.token)
        .then((res) => {
          if (res && res.status === 200) {
            const store = JSON.parse(res.request.response);
            setStoreNameValue(store.storeName);
          } else {
            setStoreNameValue(false);
          }
        })
        .catch((e) => {
          setStoreNameValue(false);
        });
    }
  }, [cnpjValue, props]);


  const [numInvoiceValue, setNumInvoiceValue] = useState('');
  const [inputNumInvoice] = useState({
    type: "text",
    name: "numInvoice",
    maxLength: 90,
    replaceValueOnChange: true,
    placeholder: "00.000.000.0000.00",
    onChange: function (numInvoice) {
      setNumInvoiceValue(numInvoice);
      return numInvoice;
    },
    onBlur: function () {
      dataLayer.push({
        event: "cadastro_nota_preencheu_numero_nf"
      });
    },
    onFocus: function () {
      pushDataLayer('focus', 'numInvoice');
    }
  });

  const [promotionalCodeValue, setPromotionalCodeValue] = useState(null);
  const [inputPromotionalCode] = useState({
    type: "text",
    name: "promotionalCode",
    maxLength: 90,
    placeholder: "Se sim, insira aqui.",
    replaceValueOnChange: true,
    onChange: function (code) {
      setPromotionalCodeValue(code);
      return code;
    },
    onBlur: function () {
      dataLayer.push({
        event: "cadastro_nota_preencheu_codigo"
      });
    },
    onFocus: function () {
      pushDataLayer("focus", "promotionalCode");
    },
  });

  // const [sellerCodeValue, setSellerCodeValue] = useState(null);
  // const [inputSellerCode] = useState({
  //   type: "text",
  //   name: "sellerCode",
  //   maxLength: 90,
  //   placeholder: "Se sim, insira aqui.",
  //   replaceValueOnChange: true,
  //   onChange: function (code) {
  //     setSellerCodeValue(code);
  //     return code;
  //   },
  //   onFocus: function () {
  //     pushDataLayer("focus", "sellerCode");
  //   },
  // });

  const [allProducts, setAllProducts] = useState([]);
  const [productsSelected, setProductsSelected] = useState(null);
  const [productsLoaded, setProductsLoaded] = useState(null);
  const [productsOptions, setProductsOptions] = useState(null);
  const [petsOptions, setPetsOptions] = useState(null);
  useEffect(() => {
    getBravectoProducts()
      .then((res) => {
        if (res && res.status === 200) {
          let productsOptions = [];
          const productsResponse = JSON.parse(res.request.response);
          productsResponse.map((i) => {
            return productsOptions.push({
              label: i.bravectoProductTitle,
              value: i.id,
            });
          });
          setProductsOptions(productsOptions);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    getAllPetsByCustomer(props.user.id)
      .then((res) => {
        if (res && res.status === 200) {
          let petsOptions = [];
          const petsResponse = JSON.parse(res.request.response);
          petsResponse.map((pet) => {
            return petsOptions.push({
              label: pet.name,
              value: pet.id,
            });
          });
          setPetsOptions(petsOptions);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [props.user.id]);

  useEffect(() => {
    if (props.user && props.user.id && !isCreatingNewPet) {
      getAllPetsByCustomer(props.user.id)
        .then((res) => {
          if (res && res.status === 200) {
            let petsOptions = [];
            const petsResponse = JSON.parse(res.request.response);
            petsResponse.map((pet) => {
              return petsOptions.push({
                label: pet.name,
                value: pet.id,
              });
            });
            setPetsOptions(petsOptions);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [isCreatingNewPet, props]);

  const [productSelect, setProductSelect] = useState();
  useEffect(() => {
    let options = {
      placeholder: "Selecione o produto",
      options: productsOptions,
      isSearchable: false,
      value: productsSelected,
      noOptionsMessage: function () {
        return "Procure novamente!";
      },
      onChange: function (select) {
        dataLayer.push({
          event: "cadastro_nota_selecionou_qual_produto"
        });
        return setProductsSelected((productsSelected) => {
          let pet =
            productsSelected && productsSelected.pet ? productsSelected.pet : 0;
          return { ...select, pet: pet };
        });
      },
      onFocus: function () {
        pushDataLayer("focus", "products");
      },
    };
    setProductsLoaded(false);
    setProductSelect(options);
    setProductsLoaded(true);
  }, [productsOptions, productsSelected]);

  const [petSelected, setPetSelected] = useState(null);
  const [petSelect, setPetSelect] = useState({
    placeholder: "Selecione um produto para liberar este campo",
    options: [],
    isSearchable: false,
    isDisabled: true,
  });
  const [qtySelected, setQtySelected] = useState(null);
  const [qtySelect, setQtySelect] = useState({
    placeholder: "Selecione um pet para liberar este campo",
    options: [],
    isSearchable: false,
    isDisabled: true,
  });

  useEffect(() => {
    if (petSelected) {
      const thisPetSelected = petSelected.value;
      const productSelected = petSelected.productId;

      const options = [
        {
          label: 1,
          value: 1,
          petId: thisPetSelected,
          productId: productSelected,
        },
        {
          label: 2,
          value: 2,
          petId: thisPetSelected,
          productId: productSelected,
        },
        {
          label: 3,
          value: 3,
          petId: thisPetSelected,
          productId: productSelected,
        },
        {
          label: 4,
          value: 4,
          petId: thisPetSelected,
          productId: productSelected,
        },
        {
          label: 5,
          value: 5,
          petId: thisPetSelected,
          productId: productSelected,
        },
      ];

      setQtySelect({
        placeholder: "Selecione a quantidade",
        options: options,
        value: qtySelected,
        isSearchable: false,
        isDisabled: false,
        noOptionsMessage: function () {
          return "Procure novamente!";
        },
        onChange: function (selectQty) {
          dataLayer.push({
            event: "cadastro_nota_selecionou_quantidade"
          });
          setQtySelected(selectQty);
          return setProductsSelected((productsSelected) => {
            return {
              ...productsSelected,
              qty: selectQty.value,
            };
          });
        },
        onFocus: function () {
          pushDataLayer("focus", "quantity");
        },
      });
    }
  }, [petSelected, qtySelected, productsSelected]);

  useEffect(() => {
    if (productsSelected) {
      const productSelected = productsSelected.value;
      const options = petsOptions.map((pet) => {
        return {
          ...pet,
          productId: productSelected,
        };
      });
      options.push({
        label: "Cadastrar novo pet",
        value: null,
      });
      setPetSelect({
        placeholder: "Selecione o pet",
        options: options,
        value: petSelected,
        isSearchable: false,
        isDisabled: false,
        noOptionsMessage: function () {
          return "Procure novamente!";
        },
        onChange: function (selectPet) {
          dataLayer.push({
            event: "cadastro_nota_selecionou_qual_pet"
          });
          if (selectPet.value === null) {
            setIsCreatingNewPet(true);
            window.scrollTo(0, 0);
          } else {
            setPetSelected(selectPet);
            return setProductsSelected((productsSelected) => {
              return {
                ...productsSelected,
                pet: selectPet,
              };
            });
          }
        },
        onFocus: function () {
          pushDataLayer("focus", "pet");
        },
      });
    }
  }, [productsSelected, petSelected, petsOptions]);

  useEffect(() => {
    function handleAddNewProduct() {
      if (qtySelected) {
        let myProducts = allProducts.length > 0 ? allProducts : [];

        myProducts = myProducts.filter((product) => {
          return !(
            product.value === productsSelected.value &&
            product.pet.value === productsSelected.pet.value
          );
        });

        myProducts.push(productsSelected);

        setAllProducts(myProducts);

        setPetSelect({
          placeholder: "Selecione um produto para liberar este campo",
          options: [],
          value: null,
          isSearchable: false,
          isDisabled: true,
        });

        setQtySelect({
          placeholder: "Selecione um pet para liberar este campo",
          options: [],
          value: null,
          isSearchable: false,
          isDisabled: true,
        });

        setProductsSelected(null);
        setPetSelected(null);
        setQtySelected(null);
      }
    }
    handleAddNewProduct();
  }, [allProducts, productsSelected, qtySelected]);

  const [storeNameValue, setStoreNameValue] = useState(null);

  // const [saveModal, setSaveModal] = useState(false);

  function handleDeleteProduct(productId) {
    let myProducts = allProducts;
    myProducts = myProducts.filter((i) => {
      return i.value !== productId;
    });
    setAllProducts(myProducts);
  }

  function findPhoto(image) {
    return !image ? setCheckImageBase64(false) : setCheckImageBase64(true);
  }

  function formValidation() {
    // setSaveModal(true);

    let formErrors = {};
    const fields = {
      imageBase64: imageBase64,
      PurchaseDate: purchaseDateValue,
      CNPJ: cnpjValue,
      NF: numInvoiceValue,
      BravectoProducts: allProducts,
      CustomerId: props.user.id,
    };
    Object.keys(fields).map((i) => {
      if (!fields[i] || fields[i].length === 0) {
        formErrors[i] = true;
      }
      return formErrors[i];
    });

    findPhoto(imageBase64);

    if (Object.keys(formErrors).length > 0) {
      setHasFormErrors(formErrors);
      window.scrollTo(0, 0);
      setIsSubmitting(false);
      return false;
    } else {
      const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
      };
      const blob = b64toBlob(fields.imageBase64.split(",")[1], "image/jpg");
      fields.imageBase64 = new File([blob], "filename.jpg");

      let products = [];
      fields.BravectoProducts.map((i) => {
        return products.push({
          BravectoProductId: i.value,
          petId: i.pet.value,
          productQuantity: i.qty,
        });
      });
      fields.BravectoProducts = products;
      if (promotionalCodeValue) {
        fields.PromotionalCode = promotionalCodeValue;
      }
      // if (sellerCodeValue) {
      //   fields.SellerCode = sellerCodeValue;
      // }
      return fields;
    }
  }

  useEffect(() => { console.log("NF", submitSuccess) }, [submitSuccess])

  function pushDataLayer(status, field) {
    window.dataLayer.push({
      gaEventCategory: "formulario-transacional",
      gaEventAction: field,
      gaEventLabel: status,
      event: "gaSendEvent",
    });
  }

  function handleSubmit(e) {
      e.preventDefault();
      setIsSubmitting(true);
      setHasFormErrors(false);
      pushDataLayer("submit", "button");
      const fields = formValidation();

      if (fields) {
        // eslint-disable-next-line no-undef
        dataLayer.push({
          event: "input_select_valor_selecionado",
          select_label: "Qual a quantidade?",
          select_value: fields.BravectoProducts[0].productQuantity
        });
        let bodyFormData = new FormData();
        bodyFormData.set("imageBase64", fields.imageBase64);
        bodyFormData.set("PurchaseDate", fields.PurchaseDate);
        bodyFormData.set("CNPJ", fields.CNPJ);
        bodyFormData.set("NF", fields.NF);
        bodyFormData.set("StoreName", storeNameValue ? storeNameValue : null);
        bodyFormData.set(
          "Extension",
          imageBase64.includes("pdf") ? "pdf" : "jpg"
        );
        if (fields.PromotionalCode) {
          bodyFormData.set("PromotionalCode", fields.PromotionalCode);
        }
        if (fields.SellerCode) {
          bodyFormData.set("SellerCode", fields.SellerCode);
        }
        bodyFormData.set("CustomerId", fields.CustomerId);
        for (let i = 0; i < fields.BravectoProducts.length; i++) {
          bodyFormData.set(
            `BravectoPurchaseList[${i}][BravectoProductId]`,
            fields.BravectoProducts[i].BravectoProductId
          );
          bodyFormData.set(
            `BravectoPurchaseList[${i}][petId]`,
            fields.BravectoProducts[i].petId
          );
          bodyFormData.set(
            `BravectoPurchaseList[${i}][productQuantity]`,
            fields.BravectoProducts[i].productQuantity
          );
        }

        postRegisterCoupon(bodyFormData, props.user.token)
          .then((res) => {
            if (res && res.status === 200) {
              setModalRegisterReminderClose(true);
              setSubmitSuccess(true);
              setIsSubmitting(false);
              pushDataLayer("submit-success", "button");
            }
          })
          .catch((e) => {
            if (parseInt(e.response.status) === 400) {
              let error = [];
              if (e.response && e.response.data) {
                const errorData = e.response.data.errors;
                if (errorData.length > 0) {
                  errorData.map((i) => {
                    setSubmitSuccess(
                      i.message ===
                        "Essa Nota Fiscal já foi cadastrada para esse CNPJ"
                        ? false
                        : null
                    );
                    return error.push(i.message);
                  });
                }
                setHasRequestErrors(error);
              }
            }
            setIsSubmitting(false);
          });
      }
  }

  function showModalConfirmSubmit() {
    setModalConfirmSubmit(true);
  }

  function handleRegisterNewInvoice() {
    setSubmitSuccess(null);
    setHasRequestErrors(null);
    setAllProducts([]);
    setStoreNameValue(null);
    setImageBase64(null);
  }

  function handleNewImage(e) {
    if (e.target.files[0]) {
      if (e.target.files[0].size > 10485760) {
        setErrorPhoto("Arquivo inválido ou maior que 10 MB");
        setImageBase64("");
      } else {
        if (e.target.files[0].type === 'application/pdf') {
          setIsFilePDF(true);
        } else {
          setIsFilePDF(false);
        }
        setErrorPhoto("");
        const reader = new FileReader();
        reader.onload = function (e) {
          setImageBase64(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  }

  const [modalContent] = useState(
    `<span>Para que sua Nota ou Cupom Fiscal seja aprovada, por favor garanta que:</span>
    <br/>
    <span>1) A foto/arquivo seja do formato .jpeg, .jpg, .gif, .png ou .pdf e tenha no máximo 10MB.</span>
    <br/>
    <span>2) As informações de Data da Compra, CNPJ da loja, nomes e quantidades de Bravecto estejam visíveis na imagem.</span>
    <br/>
    <span><b>Importante:</b> Qualquer outra imagem que não seja da Nota ou Cupom Fiscal da sua compra irá reprovar o cadastro.</span>`
  );

  const [saveModalContent] = useState(
    `
    <span>Aproveite o momento para ativar o lembrete de administração ao seu pet :)</span>
    <br />
    `
  );

  return (
    <React.Fragment>
      {isCreatingNewPet && (
        <CadastrarPet
          callback={() => {
            setIsCreatingNewPet(false);
          }}
          isNotOwnRoute={true}
        />
      )}
      <div
        id="SubirNota"
        style={{ display: isCreatingNewPet ? "none" : "block" }}
      >
        <div id="skip">
          <a href="#SubirNota__container">
            Pular para o conteúdo principal da página
          </a>
        </div>
        <h1 className="sr-only">
          Cadastrar Nota Fiscal - Clube de Vantagens Bravecto
        </h1>
        <Header />
        <Modal
          modalName={{ name: "uploadImage" }}
          modalRef={{ ref: false, route: null }}
          modalButtonValue={"Ok, entendi!"}
          modalClose={modalUploadImageClose}
          setModalClose={setModalUploadImageClose}
          modalContent={modalContent}
          action={() => {
            document.getElementById("newInvoice").click();
          }}
        />

        <ConfirmModal 
          isOpen={modalConfirmSubmit}
          onConfirm={() => {
            document.getElementById('form-cadastrar-nota-submit').click()
            setModalConfirmSubmit(false)
          }}
          onCancel={() => setModalConfirmSubmit(false)}
        />

        <Modal
          modalName={{ name: "registerReminder" }}
          modalRef={{ ref: true, route: "/cadastrar-lembrete" }}
          modalButtonValue={"Ok, cadastrar lembrete!"}
          modalClose={modalRegisterReminderClose}
          setModalClose={setModalRegisterReminderClose}
          modalContent={saveModalContent}
          action={() => { }}
        />

        <div id="SubirNota__container">
          <div id="SubirNota--title">
            <span>Cadastre a Nota ou Cupom Fiscal</span>
          </div>
          {submitSuccess === null && (
            <React.Fragment>
              <div id="SubirNota--desc">
                <span>
                  Ao cadastrar uma nova compra de produtos Bravecto, você
                  garante acesso a mais benefícios exclusivos e pode ter direito
                  à nossa exclusiva Garantia Bravecto!
                </span>
              </div>
              <div
                id="error__main"
                style={{ display: hasFormErrors ? "flex" : "none" }}
              >
                <div>
                  <span id="title">ERRO NO CADASTRO</span>
                </div>
                <div>
                  <span id="info">
                    Por favor, corrija as informações dos
                    <br /> campos assinalados em Vermelho.
                  </span>
                </div>
              </div>
              <form id="form-cadastrar-nota" onSubmit={handleSubmit}>
                <div id="row">
                  <div id="row__title">
                    <span>Adicione foto ou arquivo da Nota/Cupom</span>
                  </div>
                  <div id="row__desc">
                    Ao tirar uma foto, confira se informações como <br /> data
                    da compra e nome do produto estão aparecendo
                  </div>
                  <div id="select-a-photo">
                    <div
                      id="select-a-photo__container"
                      onClick={() => {
                        setModalUploadImageClose(true);
                      }}
                    >
                      <div id="box-photo" onClick={() => {
                        dataLayer.push({
                          event: "cadastro_nota_click_foto"
                        });
                      }}>
                        {!imageBase64 ? (
                          <React.Fragment>
                            <div id="box-photo--icon">
                              <span></span>
                            </div>
                            <div id="box-photo--archive">
                              <span>Selecionar arquivo</span>
                            </div>
                          </React.Fragment>
                        ) : (
                          <span
                            id="box-photo--invoice"
                            style={{ backgroundImage: `url('${imageBase64}')` }}
                          >{isFilePDF && (<><img src={iconPdf} alt="icon-pdf" width="30px" /> <span>PDF carregado com sucesso!</span></>)}</span>
                        )}
                      </div>
                      <input
                        name="newImage"
                        id="newInvoice"
                        type="file"
                        accept=".jpeg, .jpg, .gif, .png, .pdf"
                        onChange={handleNewImage}
                      />
                    </div>
                    <div id="photo--notfound">
                      <span id="error__field">
                        {!checkImageBase64 && !imageBase64
                          ? "Por favor, inclua a imagem do seu cupom/nota fiscal"
                          : ""}
                      </span>
                    </div>
                    <div id="photo--maxSize">
                      <span
                        style={{ color: errorPhoto ? "#b42446" : "#717274" }}
                      >
                        {hasFormErrors.imageBase64 || errorPhoto
                          ? "Arquivo inválido ou maior que 10 MB"
                          : "Limite de peso 10MB, somente arquivos .jpeg, .jpg, .gif, .png ou .pdf"}
                      </span>
                    </div>
                  </div>
                </div>
                <div id="SubirNota--title">
                  <span>Dados da Nota Fiscal</span>
                </div>
                <div id="row">
                  <div id="content">
                    <span>Data de Compra:</span>
                  </div>
                  <Input {...inputPurchaseDate} />
                  <div
                    id="error__field"
                    style={{
                      display: hasFormErrors.PurchaseDate ? "flex" : "none",
                    }}
                  >
                    <span>Preencha o campo Data de Compra</span>
                  </div>
                </div>
                <div id="row">
                  <div id="content">
                    <span>CNPJ da loja:</span>
                  </div>
                  <Input {...inputCnpj} />
                  <div
                    id="error__field"
                    style={{ display: hasFormErrors.CNPJ ? "flex" : "none" }}
                  >
                    <span>Preencha o campo de CNPJ</span>
                  </div>
                </div>

                <div id="row">
                  <div id="content">
                    <span>
                      Digite o número da Nota Fiscal:
                    </span>
                  </div>
                  <Input {...inputNumInvoice} />
                  <div id="error__field" style={{ display: hasFormErrors.NF ? 'flex' : 'none' }}>
                    <span>Preencha o campo de número da nota</span>
                  </div>
                </div>

                {/* <div id="row">
                  <div id="content">
                    <span>Possui um código do balconista?</span>
                  </div>
                  <Input {...inputSellerCode} />
                  <div
                    id="error__field"
                    style={{
                      display: hasFormErrors.SellerCode ? "flex" : "none",
                    }}
                  >
                    <span>Código inválido</span>
                  </div>
                </div> */}
                {storeNameValue && (
                  <div id="row">
                    <div id="content">
                      <span>Nome da loja:</span>
                    </div>
                    <div id="row__desc--store">
                      <span>{storeNameValue}</span>
                    </div>
                  </div>
                )}
                <div id="SubirNota--title">
                  <span>Dados do Produto</span>
                </div>
                <p>
                  Para ter direito a Garantia Bravecto, você precisa vincular
                  cada produto comprado com o seu pet!
                </p>
                <div id="row">
                  <div id="content">
                    <span>Qual produto adquirido?</span>
                  </div>
                  {productsLoaded ? (
                    <SelectCustom {...productSelect} />
                  ) : (
                    <div id="SubirNota--preLoader">
                      <Loader />
                    </div>
                  )}
                  <div
                    id="error__field"
                    style={{
                      display: hasFormErrors.BravectoProducts ? "flex" : "none",
                    }}
                  >
                    <span>Selecione ao menos um produto</span>
                  </div>
                </div>
                <div id="row">
                  <div id="content">
                    <span>Para qual pet?</span>
                  </div>
                  <SelectCustom {...petSelect} />
                  <div
                    id="error__field"
                    style={{
                      display: hasFormErrors.BravectoProducts ? "flex" : "none",
                    }}
                  >
                    <span>Selecione ou adicione um pet</span>
                  </div>
                </div>
                <div id="row">
                  <div id="content">
                    <span>Qual a quantidade?</span>
                  </div>
                  <SelectCustom {...qtySelect} />
                  <div
                    id="error__field"
                    style={{
                      display: hasFormErrors.BravectoProducts ? "flex" : "none",
                    }}
                  >
                    <span>Selecione a quantidade</span>
                  </div>
                </div>
                {/* <div id="row">
                  <button type="button" id="SubirNota--new-product"
                    onClick={handleAddNewProduct}>Adicionar novo produto</button>
                </div> */}
                <div id="row">
                  {allProducts.length > 0 && (
                    <div id="row__table">
                      <div id="table__header">
                        <div id="table__header--delete">
                          <MdDeleteForever
                            onClick={() => {
                              setAllProducts([]);
                            }}
                          />
                        </div>
                        <div id="table__title">
                          <span>Produto</span>
                        </div>
                        <div id="table__title">
                          <span>Pet</span>
                        </div>
                        <div id="table__title">
                          <span>Qtd</span>
                        </div>
                      </div>
                      {allProducts.map((i) => {
                        return (
                          <div
                            id="table__line"
                            key={`${i.value}.${i.pet.value}`}
                          >
                            <div id="table__line--delete">
                              <MdDeleteForever
                                onClick={() => {
                                  handleDeleteProduct(i.value);
                                }}
                              />
                            </div>
                            <div id="table__line--info">
                              <span>{i.label}</span>
                            </div>
                            <div id="table__line--info">
                              <span>{i.pet.label}</span>
                            </div>
                            <div id="table__line--info">
                              <span>{i.qty}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {allProducts.length > 0 && (
                    <div id="info_other_purchase__container">
                      <span id="info_other_purchase">
                        Se você adquiriu mais produtos para outros pets, basta
                        continuar selecionando no menu acima
                      </span>
                    </div>
                  )}
                </div>
                <div id="row">
                  <div id="content">
                    <span>Possui um código promocional?</span>
                  </div>
                  <Input {...inputPromotionalCode} />
                  <div
                    id="error__field"
                    style={{
                      display: hasFormErrors.PromotionalCode ? "flex" : "none",
                    }}
                  >
                    <span>Código inválido</span>
                  </div>
                </div>
                <div
                  id="error__main"
                  style={{ display: hasRequestErrors ? "flex" : "none" }}
                >
                  <div>
                    <span id="title">ERRO NO CADASTRO</span>
                  </div>
                  <div id="div__info">
                    <ul>
                      {hasRequestErrors &&
                        hasRequestErrors.map((i) => {
                          return <li key={i}>{i}</li>;
                        })}
                    </ul>
                  </div>
                </div>
                <div id="row">
                  <button
                    id="SubirNota--btn-cadastrar"
                    disabled={isSubmitting}
                    onClick={(e) => {
                      e.preventDefault();
                      setModalConfirmSubmit(true);
                    }}
                  >
                    {isSubmitting ? <LoaderForButton /> : <span>Salvar</span>}
                  </button>
                  <button disabled={isSubmitting} id="form-cadastrar-nota-submit" style={{display: 'none'}} type="submit">
                  </button>
                </div>
              </form>
            </React.Fragment>
          )}
          {submitSuccess === true && (
            <div id="SubirNota--submit">
              <div id="submit__container">
                <IoIosCheckmarkCircleOutline id="submitSuccess" />
                <div id="submit__text">
                  <span id="submit__text--little">
                    Nota cadastrada <b>com sucesso.</b>
                  </span>
                </div>
                <div id="btn-new-invoice">
                  <button
                    type="button"
                    onClick={() => {
                      handleRegisterNewInvoice();
                    }}
                  >
                    Cadastrar nova nota
                  </button>
                </div>
              </div>
            </div>
          )}
          {submitSuccess === false && (
            <div id="SubirNota--submit">
              <div id="submit__container">
                <IoIosCloseCircleOutline id="submitFail" />
                <div id="submit__text">
                  <span id="submit__text--little">
                    Essa nota já foi cadastrada.
                  </span>
                </div>
                <div id="btn-new-invoice">
                  <button
                    type="button"
                    onClick={() => {
                      handleRegisterNewInvoice();
                    }}
                  >
                    Cadastrar nova nota
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ auth }, props) => {
  return {
    ...auth,
    ...props,
  };
};

export default Connect(mapStateToProps)(CadastrarNotaFiscal);
