import React from 'react';
import { IoMdCloseCircle } from "react-icons/io";

import './ConfirmModal.css';

const ConfirmModal = ({ isOpen, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="confirm-modal-overlay">
      <div className="confirm-modal-content">
        <i className='confirm-modal-close-icon'><IoMdCloseCircle onClick={onCancel} /></i>
        <div className='confirm-modal-border-detail'></div>
        <span>
        <h2>Antes de avançar</h2>
        <h3>Certifique-se de ter cadastrado todos os produtos para seus pets.</h3>
        </span>
        <p>Se você adquiriu mais produtos para outros pets, basta continuar selecionando no menu "Quantidade de produtos". Depois de salvo, você não conseguirá cadastrar mais produtos.</p>
        <div className="confirm-modal-buttons">
          <button onClick={onCancel} className="confirm-modal-button cancel">Voltar e cadastrar mais produtos</button>
          <button onClick={onConfirm} className="confirm-modal-button confirm">Salvar</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
